import React, { useEffect, useState } from 'react';
import Event from './Event';
import './EventList.css';

const EventList = () => {
    const [events, setEvents] = useState([]);

    useEffect(() => {
        // Fetch events from the API
        fetch('https://ticket4u.at/api/Event', {
            headers: {
                'Accept': '*/*',
            },
        })
            .then(response => response.json())
            .then(data => {
                // Map API data to the desired format
                const formattedEvents = data.map(event => {
                    const startDate = new Date(event.eventDate.start_date);
                    const endDate = new Date(event.eventDate.end_date);

                    const formattedStartDate = startDate.toLocaleDateString();
                    const formattedEndDate = endDate.toLocaleDateString();

                    return {
                        imgSrc: event.picture,
                        title: event.name,
                        details: event.description,
                        organizer: event.organizer.name,
                        dateRange: `${formattedStartDate} bis ${formattedEndDate}`,
                    };
                });

                console.log("formattedEvents: ", JSON.stringify(formattedEvents));
                setEvents(formattedEvents); // Update the state with the formatted events
            })
            .catch(error => console.error('Error fetching events:', error));
    }, []);

    return (
        <section className="events">
            <h2>Events:</h2>
            {events.length > 0 ? (
                events.map((event, index) => (
                    <Event
                        key={index}
                        imgSrc={event.imgSrc}
                        title={event.title}
                        details={event.details}
                        organizer={`Organized by: ${event.organizer}`}
                        dateRange={event.dateRange}
                    />
                ))
            ) : (
                <p>Loading events...</p>
            )}
        </section>
    );
};

export default EventList;