import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
    const navigate = useNavigate();

    return (
        <div className="login-page">
            <header>
                <div className="logoLoginPage" onClick={() => navigate(-1)}>ticket4u</div>
            </header>
            <div className="container">
                <div className="login-box">
                    <h2>Login</h2>
                    <form action="#">
                        <input type="text" placeholder="Username" required />
                        <input type="password" placeholder="Password" required />
                        <button type="submit">Login</button>
                    </form>
                    <div className="social-login">
                        <p>Or continue with:</p>
                        <span className="row-container">
                            <button className="google-btn">Google</button>
                            <button className="meta-btn">Meta</button>
                        </span>
                    </div>
                    <div className="register">
                        <p>Don't have an account? <a href="#">Create one</a></p> {/* Registrieren später noch einrichten!!!!! */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
