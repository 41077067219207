import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const navigate = useNavigate();

    return (
        <header>
            <div className="welcome_label_header">Hauptseite</div>
            <div className="logo" onClick={() => window.history.back()}>ticket4u</div>
            <div className="login" onClick={() => navigate('/login')}>Login</div>
        </header>
    );
};

export default Header;
