import React from 'react';
import './Event.css';

const Event = ({ imgSrc, title, details, organizer, dateRange }) => (
    <div className="event">
        <img src={imgSrc} alt="Event Picture missing..." />
        <div className="event-details">
            <h3>{title}</h3>
            <p>{details}</p>
            <p>{organizer}</p>
            <p>{dateRange}</p>
        </div>
    </div>
);

export default Event;