import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Slideshow from './components/Slideshow';
import EventList from './components/EventList';
import Login from './components/Login';
import './App.css';

const App = () => {
    console.log('Rendering App');
    return (
        <div className="App">

            <main>
                <Routes>
                    <Route path="/" element={
                        <>  <Header />
                            <h1 id="welcome_label">Willkommen!</h1>
                            <Slideshow />
                            <EventList />
                        </>
                    } />
                    <Route path="/login" element={<Login />} />
                </Routes>
            </main>
        </div>
    );
};

export default App;
